import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 866.524 637.056">
      <path
        fill="#f1f1f1"
        d="M971.738 768.528v-72.34s28.192 51.286 0 72.34z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M973.48 768.515l-53.29-48.92s56.845 13.915 53.29 48.92z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#ffb7b7"
        d="M743.266 577.442a9.095 9.095 0 019.852-9.872l9.606-18.43 12.625 3.106-13.932 25.837a9.145 9.145 0 01-18.15-.64z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#ffb7b7"
        d="M633.871 625.527L623.218 625.526 618.15 584.437 633.873 584.438 633.871 625.527z"
      ></path>
      <path
        fill="#2f2e41"
        d="M803.326 767.325l-34.35-.002v-.434a13.37 13.37 0 0113.37-13.37h.001l20.98.001z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#ffb7b7"
        d="M719.549 617.569L709.271 620.371 693.57 582.063 708.739 577.927 719.549 617.569z"
      ></path>
      <path
        fill="#2f2e41"
        d="M891.625 758.288l-33.139 9.037-.114-.42a13.37 13.37 0 019.38-16.416h.001l20.24-5.519z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#2f2e41"
        d="M614.423 448.033L609.109 528.727 620.524 619.261 639.221 613.554 640.009 532.663 654.966 486.018 699.25 610.602 719.521 603.123 702.792 522.232 693.345 446.262 614.423 448.033z"
      ></path>
      <path
        fill="#cbcbcb"
        d="M832.823 437.806l-31.486 4.643-11.343 11.218-3.522 40.307 2.188 35.702-9.12 62.959s22.931-12.73 40.701 3.205 42.636 2.816 43.147-7.784z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#2f2e41"
        d="M816.834 488.357v-.045l15.641-50.984.202-.013c1.114-.073 27.337-1.618 33.203 11.33l.028.062-1.782 52.983 2.454 82.98-48.5 10.505-.353.076zM799.5 484.833l2.87-43.22c-20.407 1.269-20.1 15.737-20.076 16.368l-.223 64.65-4.087 69.164 14.86-1.115z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#2f2e41"
        d="M753.485 563.477l18.118-42.047 12.645-23.222 6.602 41.89-22.922 34.383z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#ffb7b7"
        d="M828.36 548.006a9.095 9.095 0 0112.325-6.526l14.617-14.777 11.146 6.692-20.934 20.578a9.145 9.145 0 01-17.154-5.967z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#2f2e41"
        d="M840.651 536.085l29.801-32.692-19.726-28.149 2.03-13.762 11.627-14.362.227.293c1.24 1.604 30.362 39.44 31.198 53.763.838 14.376-41.021 50.742-42.804 52.282l-.247.215z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <circle cx="647.916" cy="281.612" r="21.181" fill="#ffb7b7"></circle>
      <path
        fill="#2f2e41"
        d="M834.576 396.15l1.027-2.066-5.167-2.568s-5.7-9.274-16.014-6.668-14.955 4.166-14.955 4.166l-5.154 2.594 2.587 2.573-4.64 1.56 3.1 1.54-3.607 2.074 1.941 10.628s3.225-8.061 9.426-4.98 17.541-1.593 17.541-1.593l9.853 19.069s2.033-6.685 5.657-4.902c0 0 9.259-14.746-1.595-21.427z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#fff"
        d="M594.907 516.07L272.805 604.9 166.738 220.303l322.103-88.831z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M594.907 516.07L272.805 604.9 166.738 220.303l322.103-88.831zM276.92 597.65l310.738-85.697-102.933-373.233-310.737 85.697z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#e5e5e5"
        d="M418.744 303.765l-80.741 22.268a4.46 4.46 0 01-5.48-3.11l-22.266-80.742a4.46 4.46 0 013.11-5.479l80.74-22.267a4.46 4.46 0 015.48 3.11l22.267 80.741a4.46 4.46 0 01-3.11 5.48zM313.841 238.42a2.676 2.676 0 00-1.867 3.288l22.268 80.74a2.676 2.676 0 003.287 1.867l80.741-22.268a2.676 2.676 0 001.866-3.287l-22.267-80.741a2.676 2.676 0 00-3.287-1.866z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#fe7f7f"
        d="M398.277 320.04l-80.741 22.266a4.014 4.014 0 01-4.932-2.799l-22.267-80.74a4.014 4.014 0 012.8-4.932l80.74-22.267a4.014 4.014 0 014.932 2.799l22.267 80.74a4.014 4.014 0 01-2.8 4.932z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M263.489 361.969H497.217V371.001H263.489z"
        transform="rotate(-15.418 -188.631 916.627)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M269.75 384.671H503.478V393.703H269.75z"
        transform="rotate(-15.418 -182.37 939.329)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M276.011 407.372H509.73900000000003V416.404H276.011z"
        transform="rotate(-15.418 -176.11 962.03)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M287.03 447.328H520.758V456.35999999999996H287.03z"
        transform="rotate(-15.418 -165.09 1001.986)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M293.291 470.03H527.019V479.06199999999995H293.291z"
        transform="rotate(-15.418 -158.83 1024.688)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M299.552 492.732H533.28V501.764H299.552z"
        transform="rotate(-15.418 -152.568 1047.39)"
      ></path>
      <path
        fill="#fff"
        d="M698.014 603.616H363.887V204.661h334.127z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#e5e5e5"
        d="M698.014 603.616H363.887V204.661h334.127zm-328.232-5.894H692.12V210.555H369.782z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path fill="#fe7f7f" d="M279.408 244.695H470.442V253.727H279.408z"></path>
      <path fill="#fe7f7f" d="M279.408 268.178H470.442V277.21H279.408z"></path>
      <path fill="#fe7f7f" d="M279.408 291.661H470.442V300.693H279.408z"></path>
      <circle cx="263.877" cy="250.534" r="5.895" fill="#fe7f7f"></circle>
      <path fill="#e5e5e5" d="M279.408 143.505H470.442V152.537H279.408z"></path>
      <path fill="#e5e5e5" d="M279.408 166.989H470.442V176.02100000000002H279.408z"></path>
      <path fill="#e5e5e5" d="M279.408 190.472H470.442V199.50400000000002H279.408z"></path>
      <circle cx="263.877" cy="148.362" r="5.895" fill="#e5e5e5"></circle>
      <path fill="#e5e5e5" d="M279.408 346.867H470.442V355.899H279.408z"></path>
      <path fill="#e5e5e5" d="M279.408 370.35H470.442V379.382H279.408z"></path>
      <path fill="#e5e5e5" d="M279.408 393.833H470.442V402.865H279.408z"></path>
      <circle cx="263.877" cy="351.723" r="5.895" fill="#e5e5e5"></circle>
      <circle cx="515.677" cy="438.205" r="68.293" fill="#fe7f7f"></circle>
      <path
        fill="#fff"
        d="M701.336 565.32v-13.067a18.921 18.921 0 10-37.842 0v13.067a9.838 9.838 0 00-9.396 9.824v30.879h56.634v-30.88a9.838 9.838 0 00-9.396-9.823zm-18.92-25.326a12.273 12.273 0 0112.258 12.26v13.044h-24.517v-13.045a12.273 12.273 0 0112.258-12.26z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#fe7f7f"
        d="M687.506 580.57a5.09 5.09 0 10-7.955 4.206v11.065h5.727v-11.065a5.084 5.084 0 002.228-4.207z"
        transform="translate(-166.738 -131.472)"
      ></path>
      <path
        fill="#cbcbcb"
        d="M1032.262 768.122h-381a1 1 0 010-2h381a1 1 0 010 2z"
        transform="translate(-166.738 -131.472)"
      ></path>
    </svg>
  );
}

export default Icon;
