import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import SEO from '../components/Seo';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImageNew';
import DecisionsImage from '../svg/Decisions';
import SecureImage from '../svg/Secure';
import SvgCharts from '../svg/SvgCharts';

const Index = () => (
  <Layout>
    <SEO title="Qualrise Consulting" />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Traceability and Technology Consultants
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Let our experienced team help you create and execute on your end-to-end traceability
            strategy. We are experts who can understand your challenges and help you define a clear
            path forward.
          </p>
          <p className="mt-8 md:mt-12">
            <a href="https://calendly.com/qualrise/30min" target="_blank">
              <Button size="lg">Get In Touch</Button>
            </a>
          </p>
          <p className="mt-4 text-gray-600">
            Schedule a 30m session for us to meet - no obligations!
          </p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">What We Do</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Traceability</p>
              <p className="mt-4">
                We have years of experience designing and implementing full end-to-end traceability
                solutions, for a variety of manufacturing and processing customers. We see your
                traceability challenges as being unique, but will bring a deep foundational
                understanding to the table.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Technology</p>
              <p className="mt-4">
                We love and understand technology. We can help you manage your technology
                infrastructure and ensure that you're always getting the best value from the tools
                and services that you use everyday.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Consulting</p>
              <p className="mt-4">
                We want to understand your unique traceability challenges and work with you to
                define, prioritize and execute on your management strategy. We will help you face
                new challenges and leverage new opportunities.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Learn, Think, Do.</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            <b>We have deep expertise, but you are the expert about your own business.</b> With our
            <b>Traceability & Technology Audit</b>, we will learn from you and your team, so that we
            can think together about what we need to do to make your traceability solutions a
            reality.
          </p>
        </div>
      }
      secondarySlot={<DecisionsImage />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Information Technology, Security and Infrastructure Management
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our Operations IT Audit will help you understand your hidden technology vulnerabilities
            and help you find opportunities to optimize value. Our Cybersecurity Readiness Audit
            will provide you with the basis for a sound strategy against information breaches and
            intrusions.
          </p>
        </div>
      }
      secondarySlot={<SecureImage />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Your Partner for The Future</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once we understand your unique challenges and opportunities, we will be in a position to
            understand new challenges that you encounter as you grow. We're invested in your future.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to talk about your Traceability solution?</h3>
      <p className="mt-8 text-xl font-light">
        We're very much looking forward to seeing how we can help you.
      </p>
      <p className="mt-8">
        <a href="https://calendly.com/qualrise/30min" target="_blank">
          <Button size="xl">Get Started Now</Button>
        </a>
      </p>
    </section>
  </Layout>
);

export default Index;
