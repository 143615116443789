import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 769.411 524.375">
      <circle cx="174.757" cy="6.813" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="6.813" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="243.735" cy="6.813" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="6.813" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="6.813" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.757" cy="41.735" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="41.735" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="243.735" cy="41.735" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="41.735" r="6.813" fill="#77a2f7"></circle>
      <circle cx="312.713" cy="41.735" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="381.691" cy="41.735" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.757" cy="76.657" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="76.657" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="243.735" cy="76.657" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="76.657" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="76.657" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.757" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="243.735" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="347.202" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="381.691" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="416.18" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="450.669" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="485.158" cy="111.579" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="146.501" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="347.202" cy="146.501" r="6.813" fill="#77a2f7"></circle>
      <circle cx="381.691" cy="146.501" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="416.18" cy="146.501" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="450.669" cy="146.501" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="485.158" cy="146.501" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="181.423" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="347.202" cy="181.423" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="381.691" cy="181.423" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="416.18" cy="181.423" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="450.669" cy="181.423" r="6.813" fill="#77a2f7"></circle>
      <circle cx="485.158" cy="181.423" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="347.202" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="381.691" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="416.18" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="450.669" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="485.158" cy="216.345" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.757" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="243.735" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="312.713" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="347.202" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="381.691" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="416.18" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="450.669" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="485.158" cy="251.267" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.753" cy="286.188" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="286.189" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="174.753" cy="321.11" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="209.246" cy="321.111" r="6.813" fill="#e6e6e6"></circle>
      <circle cx="278.224" cy="321.111" r="6.813" fill="#e6e6e6"></circle>
      <path
        fill="#e6e6e6"
        d="M837.295 268.197h-270a5.006 5.006 0 01-5-5V196.22a5.006 5.006 0 015-5h270a5.006 5.006 0 015 5v66.976a5.006 5.006 0 01-5 5zm-270-74.976a3.003 3.003 0 00-3 3v66.976a3.003 3.003 0 003 3h270a3.003 3.003 0 003-3V196.22a3.003 3.003 0 00-3-3z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <circle cx="387" cy="41.896" r="21" fill="#77a2f7"></circle>
      <path
        fill="#e6e6e6"
        d="M650.794 215.708a3.5 3.5 0 100 7h93a3.5 3.5 0 100-7zM650.794 236.708a3.5 3.5 0 100 7h165a3.5 3.5 0 100-7zM490.295 404.197h-270a5.006 5.006 0 01-5-5V332.22a5.006 5.006 0 015-5h270a5.006 5.006 0 015 5v66.976a5.006 5.006 0 01-5 5zm-270-74.976a3.003 3.003 0 00-3 3v66.976a3.003 3.003 0 003 3h270a3.003 3.003 0 003-3V332.22a3.003 3.003 0 00-3-3z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <circle cx="40" cy="177.896" r="21" fill="#77a2f7"></circle>
      <path
        fill="#e6e6e6"
        d="M303.794 351.709a3.5 3.5 0 000 7h97a3.5 3.5 0 000-7zM303.794 372.709a3.5 3.5 0 000 7h165a3.5 3.5 0 000-7z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#3f3d56"
        d="M734.295 551.197h-270a5.006 5.006 0 01-5-5V479.22a5.006 5.006 0 015-5h270a5.006 5.006 0 015 5v66.976a5.006 5.006 0 01-5 5zm-270-74.976a3.003 3.003 0 00-3 3v66.976a3.003 3.003 0 003 3h270a3.003 3.003 0 003-3V479.22a3.003 3.003 0 00-3-3z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <circle cx="284" cy="324.896" r="21" fill="#77a2f7"></circle>
      <path
        fill="#e6e6e6"
        d="M547.794 498.709a3.5 3.5 0 000 7h89a3.5 3.5 0 000-7zM547.794 519.709a3.5 3.5 0 000 7h165a3.5 3.5 0 000-7zM879.544 534.21c-11.726 35.178-59.695-2.131-59.695-2.131L832.64 398.83c19.187-14.923 39.44-2.132 39.44-2.132z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M661.731 464.458L678.076 464.63 673.993 511.745 661.733 511.746 661.731 464.458z"
      ></path>
      <path
        fill="#2f2e41"
        d="M873.9 695.555h24.144a15.404 15.404 0 0115.386 15.387v.5H873.9z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M617.421 464.458L599.327 464.457 605.159 511.745 617.419 511.746 617.421 464.458z"
      ></path>
      <path
        fill="#2f2e41"
        d="M796.309 711.441v-.5a15.387 15.387 0 0115.387-15.386l24.144.001v15.887zM875.922 691.396l-21.413-124.822-19.84 123.213-20.1 1.117-5.36-165.086.404-.09c3.323-.744 81.537-17.923 94.358 6.652l.065.125-10.701 159.453z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <circle cx="637.934" cy="170.843" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#2f2e41"
        d="M873.862 544.737c-2.88-.797-4.954-4.103-6.53-10.405-.27-1.087-25.771-109.712-4.206-129.392l4.97-13.808 6.803 4.535c2.407.023 27.021.646 29.125 16.423 2.06 15.444 7.665 118.913 2.03 125.35a1.219 1.219 0 01-.927.469c-5.71 0-7.444-6.337-7.851-8.356-1.358.562-5.402 2.628-12.049 9.275-4.117 4.117-7.345 6.09-10.012 6.09a5.037 5.037 0 01-1.353-.18z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#2f2e41"
        d="M820.561 538.828c-6.648-6.648-10.693-8.714-12.048-9.275-.407 2.02-2.141 8.356-7.852 8.356a1.219 1.219 0 01-.927-.47c-5.634-6.435-.029-109.905 2.03-125.349 2.104-15.777 26.718-16.4 29.125-16.423l6.803-4.535 4.97 13.808c21.564 19.68-3.935 128.306-4.206 129.392-1.576 6.302-3.65 9.608-6.53 10.405a5.037 5.037 0 01-1.352.181c-2.667 0-5.896-1.973-10.013-6.09zM873.506 372.685a25.204 25.204 0 00-12.208-45.54c-2.26-.227-4.696-.193-6.57-1.477-1.666-1.14-2.563-3.12-4.094-4.435a8.929 8.929 0 00-6.926-1.746 25.062 25.062 0 00-6.962 2.287c-5.446 2.428-11.236 5.417-13.714 10.84-2.147 4.698-1.2 10.49 1.84 14.667a20.41 20.41 0 0013.016 7.686 32.535 32.535 0 0015.285-1.377c3.241-1.02 6.442-2.41 9.838-2.523a13.37 13.37 0 0110.145 22.564"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M739.636 518.794a10.8 10.8 0 0010.547-11.048l14.324-24.167-11.067-10.146-15.088 23.811a10.801 10.801 0 001.284 21.55zM905.808 545.269a10.8 10.8 0 003.15-14.947l-.549-28.087-14.75-2.798-.29 28.187a10.801 10.801 0 0012.44 17.645z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#2f2e41"
        d="M743.993 479.936l-.167-.265.165-.266c21.484-34.499 66.58-77.593 67.033-78.025l.142-.136 5.57-.057 6.02 15c-1.874 3.606-52.395 74.11-59.066 78.786a4.603 4.603 0 01-3.685.695 9.699 9.699 0 01-3.124-1.33c-6.156-3.814-12.573-13.903-12.888-14.402zM890.852 519.303a4.57 4.57 0 01-2.707-2.484c-3.217-7.505 10.57-102.303 11.158-106.334l.11-.763 4.661 2.953.046.201c.138.606 13.712 60.997 10.033 101.126l-.026.287-.262.122c-.56.26-11.868 5.452-19.404 5.452a10.192 10.192 0 01-3.61-.56z"
        transform="translate(-215.294 -187.813)"
      ></path>
      <path
        fill="#3f3d56"
        d="M983.706 712.187h-266a1 1 0 010-2h266a1 1 0 010 2z"
        transform="translate(-215.294 -187.813)"
      ></path>
    </svg>
  );
}

export default Icon;
